<!--
 * @Author: 刘格优
 * @Date: 2020-04-09 20:36:24
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-19 11:08:39
 -->

<template>
  <div>
    <div class="content">
      <div>
        <img src="@/assets/nh/newyt.jpg"
             alt="" />
      </div>
      <div class="bottom">
        <img src="@/assets/nh/newys.png"
             alt=""
             class="hlfx" />
        <!-- <img src="@/assets/nh/newya.png" alt="" /> -->
        <img src="@/assets/nh/newyb.png"
             alt="" />
        <img src="@/assets/nh/newyc.png"
             alt="" />
        <img src="@/assets/nh/newyd.png"
             alt="" />
        <img src="@/assets/nh/newyyq.png"
             alt=""
             class="nowyx"
             @click="toyx" />
      </div>
    </div>
    <img src="@/assets/nh/newygz.png"
         alt=""
         class="hdgz"
         @click="togz" />
    <md-landscape v-model="yhqshowPic"
                  class="yhq">
      <div class="y-box">
        <img src="@/assets/nh/newhdgz.jpg"
             alt="" />
        <!-- <div class="words">
          <p class="nrhdgz">活动规则</p>
          <p>
            1、资质注册认证成功后，即送全场产品
            <span>满300元奖励10元新人券</span>
          </p>
          <p>
            2、邀请好友认证后，您可获得指定产品
            <span>满300元奖励10元邀新券</span>
          </p>
          <p>
            3、已认证好友30天内成功出首单，您可享受
            <span>好友保费的2%</span>邀新奖励
          </p>
        </div> -->
        <!-- <div class="words">
          <p class="nrhdgz">活动规则</p>
          <span class="line"></span>
          <p>
            <img src="@/assets/nh/activityone.png" alt="" />
            5月15日24时活动结束，邀请好友注册且排名为<span>系统前10名</span>，即可获得好礼一<span
              class="circle"
            ></span>
            <span>邀请注册奖励</span>，邀请的注册好友越多，获奖机会更多
            <span class="gzms">
              *全程数据由平台邀新系统进行统计，活动结束后，5月18日会在[汇立天下]平台公众号发布获奖名单，请获奖的10名用户于5月26日前，请联系平台客服或公众号留言，逾期视为放弃。奖品将在5月31日前完成寄送
            </span>
            <span class="gzms center">
              *注册用户数据异常或系统判定刷单数据视为无效
            </span>
          </p>
          <p>
            <img src="@/assets/nh/activitytwo.png" alt="" />
            新人成功认证展业资格证送<span>10元新人券</span>
            (购买全:场任意产品满300元保费奖励10元)
          </p>
          <p>
            <img src="@/assets/nh/activitythree.png" alt="" />
            邀请好友认证展业资格后，您可获得指定产品<span>满300元奖励10元邀请券</span>，多邀请多得(购买指定产品满300元保费奖励10元)
            <span class="gzms sjzf">
              *只要是通过您分享的链接成功交易的产品，系统自动使用此券
            </span>
          </p>
          <p>
            <img src="@/assets/nh/gz4.png" alt="" />
            已认证展业资格好友30天内成功出首单，您可享受<span
              >好友保费的2%邀请奖励</span
            >
            <span class="gzms sjzf">
              *新人奖励券和指定产品券兑现与推广费同时发放至个人用户中心。如有疑问，请联系平台客服咨询，本活动内容由[汇立天下]解释所有
            </span>
          </p>
        </div> -->
        <div class="imgbg">
          <img src="@/assets/nh/newyclose.png"
               alt=""
               class="closeimg"
               @click="yhqshowPic = false" />
        </div>
      </div>
    </md-landscape>
  </div>
</template>

<script>
import config from "@/config";
const { redirect_uri } = config;
import { wechatshare } from "@/lib/wechat_share";
import { getStorage } from "@/lib/util";
export default {
  data () {
    return {
      sharelogo: "",
      yhqshowPic: false,
      user: {}
    };
  },

  created () {
    let logodata = getStorage("sys_info", {});
    this.user = getStorage("u_s", {});
    this.sharelogo = logodata.sharelogo ? logodata.sharelogo : "";
    this.share();
  },

  methods: {
    togz () {
      // this.yhqshowPic = true;
      this.$router.push("/invitegz");
    },
    toyx () {
      if (this.user.rytype == "Y") {
        this.$router.push("/login");
      } else {
        // /Invitedetail
        this.$router.push("/Invitedetail");
      }
    },
    share () {
      let info = getStorage("u_s", {});
      let shareurl = `${redirect_uri}/Invitenew?tjempno=${info.empno}`;

      wechatshare(
        "加入汇立天下，送你300元+50元红包，和我一起签单赚钱吧",
        `一站式保险平台，产品优，推广费高，收入多，保险还可以这样玩`,
        this.sharelogo,
        encodeURI(shareurl),
        redirect_uri,
        "no",
        "",
        "",
        "",
        "找副业看这里，加入汇立天下，注册领现金，带你玩“赚”保险"
      );
    }
  }
};
</script>
<style scoped lang="stylus">
.content {
  height: 100vh;
  overflow-y: auto;
  background-color: #fed787;
  position: relative;
}

.y-box {
  height: 10rem;
  overflow-y: auto;
}

.center {
  text-align: center;
}

.sjzf {
  text-indent: 0.4rem;
}

.bottom {
  box-sizing: border-box;
  background-color: #fd713a;
  padding: 0 0.4rem;
  margin: 0 0.3rem 0.6rem;
  text-align: center;
  position: absolute;
  top: 10rem;

  .hlfx {
    width: 60%;
  }

  .nowyx {
    padding: 0.6rem 0 1rem;
  }

  img {
    padding-bottom: 0.5rem;
  }
}

.hdgz {
  width: 17%;
  height: auto;
  position: absolute;
  top: 5%;
  right: 0px;
}

.yhq {
  /deep/ .md-landscape-content {
    width: 80vw;
    height: 11.5rem;
  }

  /deep/ .md-landscape-close {
    display: none;
  }

  .closeimg {
    width: 8vw;
    height: 8vw;
    position: absolute;
    bottom: 0px;
    left: 37vw;
  }

  .words {
    background-color: #ffffff;
    padding: 2vh 0.5rem;

    p {
      margin: 0.5rem 0;
      line-height: 0.6rem;
      color: #676767;
      font-size: 0.36rem;

      span {
        font-weight: 600;
        color: #383838;
        font-size: 0.36rem;
      }

      img {
        width: 0.51rem;
        height: 0.51rem;
        position: relative;
        top: 0.1rem;
      }

      .gzms {
        display: block;
        font-size: 0.29rem;
        color: rgba(103, 103, 103, 1);
        font-weight: normal;
        line-height: 0.48rem;
        margin-top: 0.3rem;
      }

      .circle {
        display: inline-block;
        width: 0.1rem;
        height: 0.1rem;
        margin: 0 0.1rem;
        background-color: #383838;
        border-radius: 50%;
      }
    }

    .nrhdgz {
      font-weight: 600;
      color: #383838;
      text-align: center;
      font-size: 0.45rem;
      margin: 0.3rem 0;
    }

    .line {
      display: block;
      border: 0.02rem solid #383838;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
